import { createI18n } from "vue-i18n";
import get from "lodash.get";
import merge from "lodash.merge";
import { nextTick } from "vue";

import { COOKIE_DAYS, COOKIE_NAME, SUPPORTED_LOCALES } from "./config";

const locale = getStartingLocale();
const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale,
  // If there are no translations set for locale above, use this option instead
  // https://kazupon.github.io/vue-i18n/guide/fallback.html
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {},
});

function getBrowserLocale(options = {}): string | undefined {
  const opt = merge({ codeOnly: false }, options);
  const navigatorLocale = get(navigator, "languages[0]", navigator.language);

  if (!navigatorLocale) {
    return undefined;
  }

  if (!opt.codeOnly) {
    return navigatorLocale.trim();
  }

  const langCode = navigatorLocale.trim().split(/-|_/).slice(0, 1);
  return langCode ?? undefined;
}

export function setLocaleCookie(locale: string) {
  const date = new Date();
  date.setTime(date.getTime() + COOKIE_DAYS * 24 * 60 * 60 * 1000);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = COOKIE_NAME + "=" + locale + expires + "; path=/";
}

export function getLocaleFromCookie(): string | void {
  const nameEQ = COOKIE_NAME + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return;
}

function getStartingLocale(): string {
  const defaultLocale =
    getLocaleFromCookie() || process.env.VUE_APP_I18N_LOCALE || "en";
  const browserLocale = getBrowserLocale({ codeOnly: true }) ?? defaultLocale;
  const useBrowserLocale = SUPPORTED_LOCALES.includes(browserLocale);
  if (useBrowserLocale) {
    return browserLocale;
  }

  return defaultLocale;
}

export async function loadLocaleMessages(locale: string): Promise<void> {
  try {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
    );
    i18n.global.setLocaleMessage(locale, messages.default);
    return nextTick();
  } catch (e) {
    console.error(e);
  }
}

export function setI18nLanguage(locale: string) {
  i18n.global.locale.value = locale;
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  const root = document.querySelector("html");
  if (!root) {
    return;
  }
  root.setAttribute("lang", locale);
}

export async function setCurrentLocale(
  current: string
): Promise<string | void> {
  const supported = SUPPORTED_LOCALES.includes(current);
  const locale = supported ? current : getStartingLocale();

  await loadLocaleMessages(locale);
  setLocaleCookie(locale);
  setI18nLanguage(locale);
  return locale;
}

function setupI18n() {
  setI18nLanguage(locale);
  return i18n;
}
export default setupI18n();
