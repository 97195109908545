import { ROUTE_PATHS } from "@/router/config";
import { Configuration, LogLevel, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

export const POLICIES = {
  login: process.env.VUE_APP_MSAL_LOGIN_POLICY || "",
  profile: process.env.VUE_APP_MSAL_PROFILE_POLICY || "",
};
export const config: Configuration = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID || "",
    authority: process.env.VUE_APP_MSAL_AUTHORITY || "",
    knownAuthorities: [process.env.VUE_APP_MSAL_KNOWN_AUTHORITY || ""],
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_LOGOUT_URI || "",
    redirectUri: ROUTE_PATHS.MSAL_AUTH
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(config);

export const redirectRequest: RedirectRequest = {
  scopes: ["openid", "offline_access", "profile"],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["openid", "offline_access", "profile"],
  redirectUri: ROUTE_PATHS.MSAL_AUTH
};

export function loginRequestWithLocale(locale: string): RedirectRequest {
  return {
    ...loginRequest,
    extraQueryParameters: {
      ui_locales: locale
    }
  }
}

export default function manageAccountRequest(locale: string): RedirectRequest {
  return {
    scopes: ["openid", "offline_access", "profile"],
    extraQueryParameters: {
      p: POLICIES.profile,
      ui_locales: locale
    }
  }
}

export const graphConfig = {
  endpoint: process.env.VUE_APP_MSAL_GRAPH_API || "",
};

export enum ERROR_CODES {
  CANCELED_ACTION = "AADB2C90091"
};