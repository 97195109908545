import { createApp } from "vue";
import PrimeVue from "primevue/config";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "@/assets/css/md-light-indigo.css";
import "@/assets/scss/vp-theme.scss";

import App from "@/App.vue";
import { msalInstance, msalPlugin } from "@/plugins/msal";
import I18n from "@/plugins/i18n";
import Router from "@/router";
import Store from "@/store";

// All Global Components here
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Menu from "primevue/menu";
import Checkbox from "primevue/checkbox";
import Password from "primevue/password";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Panel from "primevue/panel";
import InputSwitch from "primevue/inputswitch";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import { AuthenticationResult, EventType } from "@azure/msal-browser";

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);
app
  .use(I18n)
  .use(Store)
  .use(Router)
  .use(PrimeVue)
  .use(msalPlugin, msalInstance)
  .use(ToastService);

app
  .component("Button", Button)
  .component("Checkbox", Checkbox)
  .component("Dialog", Dialog)
  .component("Divider", Divider)
  .component("Dropdown", Dropdown)
  .component("InputSwitch", InputSwitch)
  .component("Menu", Menu)
  .component("Password", Password)
  .component("Panel", Panel)
  .component("RadioButton", RadioButton)
  .component("Toast", Toast)
  .component("Textarea", Textarea);

Router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
