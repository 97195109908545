import { createRouter, createWebHistory } from "vue-router";
import { ROUTES } from "@/router/config";
import {
  registerAuthenticationGuard,
  registerI18nGuard,
} from "@/router/guards";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: ROUTES,
});

router.beforeEach((from, to) => {
  console.log(`From ${from.fullPath} to ${to.fullPath}`);
})

registerI18nGuard(router);
registerAuthenticationGuard(router);

export default router;
