/* eslint-disable @typescript-eslint/no-unused-vars */
import { RouteLocationNormalized, Router } from "vue-router";

import get from "lodash.get";
import { setCurrentLocale } from "@/plugins/i18n";
import { ROUTE_PATHS } from "./config";
import { msalInstance } from "../plugins/msal/config";
import { InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { loginRequest } from "@/plugins/msal/config";

export function registerAuthenticationGuard(router: Router): void {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      if (to.meta.requiresAuth) {
        const request = {
            ...loginRequest,
            redirectStartPage: to.fullPath
        }
        const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
        return shouldProceed || `/${to.params.locale}/${ROUTE_PATHS.FAILED}`;
    }

    // fallback for logout page, if authenticated, go to overview
    if(to.path.indexOf(ROUTE_PATHS.LOGOUT) !== -1) {
      const isAuthenticated = await isAuthenticatedNoRedirect(msalInstance);
      if (isAuthenticated) {
        return `/${to.params.locale}`;
      }
    }

    return true;
  });
}

export async function registerI18nGuard(router: Router): Promise<void> {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    const currentLocale = get(to, "params.locale", "");
    const locale = await setCurrentLocale(currentLocale);

    if (to.path === "/" || currentLocale !== locale) {
      return `/${locale}`;
    } else {
      return true;
    }
  });
}

export async function isAuthenticated(instance: PublicClientApplication, interactionType: InteractionType, loginRequest: PopupRequest|RedirectRequest): Promise<boolean> {    
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
  return instance.handleRedirectPromise().then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
          return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
          return instance.loginPopup(loginRequest).then(() => {
              return true;
          }).catch(() => {
              return false;
          })
      } else if (interactionType === InteractionType.Redirect) {
          return instance.loginRedirect(loginRequest).then(() => {
              return true;
          }).catch(() => {
              return false;
          });
      }

      return false;
  }).catch(() => {
      // check for redirect after cancellation of e.g. manage account, when already logged in:
      const accounts = instance.getAllAccounts();
      if(accounts.length > 0) {
        return true;
      }

      // else return false
      return false;
  });
}
function isAuthenticatedNoRedirect(instance: PublicClientApplication): Promise<boolean> {
  return instance.handleRedirectPromise().then(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
        return true;
    }

    return false;
}).catch(() => {
    return false;
});
}

