import { Emojis } from "@/helpers/emoji";
import { RouteRecordRaw } from "vue-router";

const Basic = () => import("@/layouts/Basic.vue");
const Default = () => import("@/layouts/Default.vue");
const Manage = () => import("@/views/Manage.vue");
const Registration = () => import("@/views/Registration.vue");
const Failed = () => import("@/views/Failed.vue");
const GroupRegistration = () =>
  import("@/views/registration/GroupRegistration.vue");
const IndividualRegistration = () =>
  import("@/views/registration/IndividualRegistration.vue");
const Home = () => import("@/views/Home.vue");
const NotFound = () => import("@/views/NotFound.vue");

export enum REGISTRATION_PATHS {
  GROUP = "group",
  SINGLE = "individual",
  THANKS = "done",
}

export enum ROUTE_PATHS {
  MANAGE = "manage/:type/:id",
  MSAL_AUTH = "/authorised",
  LOGOUT = "logout",
  REGISTRATION = "registration",
  HOME = "",
  FAILED = "failed"
}

export const ROUTES: Array<RouteRecordRaw> = [
  {
    // MSAL Special Entry Route
    // Used as redirectUri
    path: ROUTE_PATHS.MSAL_AUTH,
    redirect: "/",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:locale/",
    component: Default,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTE_PATHS.HOME,
        component: Home,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "manage",
        path: ROUTE_PATHS.MANAGE,
        component: Manage,
      },
      {
        path: ROUTE_PATHS.LOGOUT,
        component: Basic,
        props: {
          emoji: Emojis.WAVE,
          translationKey: "logout",
          goTo: "https://golazo.com",
        },
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: ROUTE_PATHS.FAILED,
        name: "Failed",
        component: Failed,
        meta: {
          requiresAuth: false,
        }
      },
      {
        path: ROUTE_PATHS.REGISTRATION,
        component: Registration,
        children: [
          {
            path: REGISTRATION_PATHS.THANKS,
            component: Basic,
            props: {
              emoji: Emojis.PARTY,
              translationKey: "registered",
            },
            meta: {
              hideHeader: true,
              requiresAuth: false,
            },
          },
          {
            path: REGISTRATION_PATHS.GROUP,
            component: GroupRegistration,
            meta: {
              requiresAuth: false,
            },
          },
          {
            path: REGISTRATION_PATHS.SINGLE,
            component: IndividualRegistration,
            meta: {
              requiresAuth: false,
            },
          },
        ],
      },
    ],
  },
  {
    // Fallback Url
    path: "/:pathMatch(.*)*",
    component: NotFound,
    meta: {
      hideNavigation: true,
    },
  },
];
